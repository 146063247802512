import React from 'react'
import "./SectionFour.css";
import { Link } from "react-router-dom";
import { Link as LinkRoll } from "react-scroll";
import Zoom from 'react-reveal/Zoom';

import woman_relaxing from "../../assets/male_nurse_III.webp";

const SectionFour = () => {
  return (
    <>
      
      {/* MENTAL HEALTH SERVICES */}
      <div className="sectionFour">


          <div className="sectionFourContent container">




          <div className="sectionFourRight">
          <Zoom duration={2000}>
          <h1>WHY CHOOSE WITTY PERSONNELFOR HEALTHCARE STAFFING?</h1>            
          <h2>We're here for you every step of the way.</h2>

            <p>
            At Witty Personnel, we are committed to providing expert healthcare staffing solutions tailored to the unique needs of hospitals, clinics, and healthcare facilities. Our team of experienced healthcare recruiters leverages cutting-edge technology and data-driven strategies to connect organizations with highly qualified nurses, allied health professionals, and medical specialists.</p>

            <p>Beyond simply filling positions, we focus on long-term staffing success, ensuring the right match between healthcare providers and skilled professionals. With a commitment to quality, integrity, and operational efficiency, we help healthcare organizations enhance patient care, improve workforce stability, and streamline hiring processes.</p> 
            <p>Partner with Witty Personnel for a trusted healthcare recruitment agency that prioritizes your success and the well-being of your patients.</p>

            <br/>

            <div className='sectionFourRightBtn'>
              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                  <Link to="/about-us">
                        <button>Learn More</button>
                  </Link>
              </LinkRoll>
          </div>
          </Zoom>

          </div>


          <div className="sectionFourLeft">

              <div className='sectionFourLeftImg'>
                <img src={woman_relaxing} alt="woman_relaxing" loading="lazy" />
              </div>

          </div>


          </div>
        </div>


    </>
  )
}

export default SectionFour
