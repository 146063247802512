import React from 'react'
import "./Component3.css";
import { Link } from "react-router-dom";
import { Link as LinkRoll } from "react-scroll";
import Zoom from 'react-reveal/Zoom';

import woman_relaxing from "../../assets/professionals.webp";

const Component3 = () => {
  return (
    <>
      
      {/* MENTAL HEALTH SERVICES */}
      <div className="component3">

          <div className="component3-content container">

          <div className="c3-left">
          <Zoom duration={2000}>

          <h1>ABOUT US</h1>            
          <h2>Commitment. Quality. Expertise.</h2>

            <p>
            At Witty Personnel, we are committed to connecting healthcare facilities with top-tier medical and allied health professionals who drive quality patient care. Our mission is to deliver customized healthcare staffing solutions that enhance clinical excellence and operational efficiency for every client.</p>
            <p>With extensive industry expertise, our team of healthcare recruiters specializes in identifying, vetting, and placing highly qualified nurses, mental health specialists, telehealth providers, counselors, physical therapists, behavioral health experts, and correctional health professionals.</p>
            <p>Through our rigorous screening process, we ensure that every candidate not only meets the highest professional standards but also upholds core values of compassion, integrity, and dedication. By partnering with [Your Company Name], healthcare organizations gain access to a skilled, reliable workforce, empowering them to maintain exceptional patient care and operational success.</p> <br/>

            <div className='c3-btn'>
              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                  <Link to="/about-us">
                        <button>Learn More</button>
                  </Link>
              </LinkRoll>
          </div>
          </Zoom>

          </div>


          <div className="c3-right">
            <div className='c3-right-img'>
              <img src={woman_relaxing} alt="woman_relaxing" loading="lazy" />
            </div>
          </div>


          </div>
        </div>


    </>
  )
}

export default Component3
